import { TooltipEntries
} from "src/app/pages/general/elevate-tooltip/elevate-tooltip.component";

export const tooltips: TooltipEntries = {
    "Topics": {
        styles: {},
        content: `
## Topics in Elevate

**TODO**
In Elevate, in-context comments revolutionize how we engage with an audience composed of individuals managing an entity or project item alongside other interested parties or experts. These comments foster ongoing exchanges, allowing new participants to access prior guidance, maintain context, and contribute effectively.

Here are key points to understand about comments and the audience roles:

### Audience
The comment audience consists solely of individuals assigned to the specific entity or project under consideration. This assignment can be direct or through an assigned group or organization they belong to.

To view the distinct list of audience members for any comment, simply click the Audience icon. This list represents the users actively participating in the comment.

### Response Expectations
The audience’s notification preferences and activity directly impact response times. Faster replies will more likely occur from those who have been recently active and receive notifications via Slack or other instant messaging platforms. Individual preferences may vary based on company setup. For example, if your organization has elected to install the Elevate Slack BOT.

If users are using Mail notifications, it means Elevate sends out daily emails to this user, with direct links back into this comment therefore they will likely not observe a new entry until the next time they log back into Elevate or review that email and click the comment link.

### Engaging Less Active Users
If a specific user is not frequently active within Elevate, we offered convenient alternative direct communication channels. While we encourage comments occur within Elevate, you can directly access a user’s email from the Audience window for one-on-one correspondence. Elevate will prepare the subject and body of the email to contain a link directly back into Elevate and place them directly into the comment from which the audience window was launched.

### Privacy Public Entities
Comments within entities marked as “Privacy Public” in Elevate allow unassigned users to participate in comment. If they enter something into the comment, they become part of the notification process of future chats.

### Live Chat/Comment Exchanges
Once one or more participants are logged in and actively viewing the same comment the chat exchanges will become more of a near real-time exchange between those active parties until participants leave their open comment view.
`
    }
}
