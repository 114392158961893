import { NgIf, AsyncPipe, DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { buildMarkdown } from 'src/app/components/markdown-editor/markdown-builder';
import { UserPictureComponent } from 'src/app/components/user-picture/user-picture.component';
import { TenantService } from 'src/app/services/tenant.service';
import { ThemeLoaderService } from 'src/app/services/theme-loader.service';
import { UserService } from 'src/app/services/user.service';
import { MarkdownPipe } from 'src/app/utils/pipes/markdown.pipe';
import { Attachment, DTO, DTOMetaData } from 'src/dto/dto';

@Component({
  selector: 'app-topic-panel',
  templateUrl: './topic-panel.component.html',
  styleUrls: ['./topic-panel.component.scss'],
  standalone: true,
    imports: [
        NgIf,
        AsyncPipe,
        DatePipe,
        MatIconModule,
        MatButtonModule,
        UserPictureComponent,
        MarkdownPipe
    ],
})
export class TopicPanelComponent implements OnInit {
    @Input() asset: DTO;

    public contents = "";

    private _note: Attachment;
    @Input() set note( value: Attachment) {
        this._note = value;

        // Clean up the description for proper markdown conversion.
        buildMarkdown(this.asset, undefined, value.description).then(({name, markdown, variables}) => {
            this.contents = markdown;
        });
    }
    public get note() {
        return this._note;
    }

    @Input() openEditor: Function = null;

    constructor(
        public readonly theme: ThemeLoaderService,
        public readonly  user: UserService,
        private readonly tenant: TenantService,
        private readonly elRef: ElementRef
    ) { }

    private readonly CSSVariables = {
        panelGap: 12,
        collapsedHeight: 42,
        reservedHeight: 32,
        titleHeight: 36
    }

    public bubbleColor = "";

    ngOnInit() {
        const panel = this.elRef.nativeElement;
        const styles = getComputedStyle(panel);
        this.bubbleColor = styles.getPropertyValue('--bubbleBackground');

        panel.style.height = this.CSSVariables.collapsedHeight + this.CSSVariables.panelGap + "px";
    }

    togglePanel(forceExpand = false) {
        const panel = this.elRef.nativeElement;
        const content = panel.querySelector(".content");

        if (forceExpand || !panel.classList.contains('Expanded')) {
            panel.classList.add('Expanded');
            panel.style.height = content.offsetHeight + this.CSSVariables.reservedHeight + this.CSSVariables.titleHeight + "px";
        }
        else {
            panel.classList.remove('Expanded');
            panel.style.height = this.CSSVariables.collapsedHeight + this.CSSVariables.panelGap + "px";
        }
    }

    editNote() {
        this.openEditor(this.note).then(updatedNote => {
            if (!updatedNote) return null;

            const panel = this.elRef.nativeElement;

            if (updatedNote == -1) {
                panel.classList.remove('Expanded');
                panel.classList.add('Deleted');
                panel.style.height = this.CSSVariables.collapsedHeight + this.CSSVariables.panelGap + "px";

                this.note.id = -1; // Triggers the "Deleted" visual.
            }
            else {
                this.note = updatedNote as Attachment;
                panel.style.height = "fit-content";
            }
        });
    }

    async onSave() {
        const panel = this.elRef.nativeElement;
        this.note.data = typeof this.note.data == "string"
                       ? this.note.data
                       : JSON.stringify(this.note.data);

        // Don't submit the _markdown property!
        //const markdown = this.note["_markdown"];
        //delete this.note["_markdown"];
        this.note = await DTO.attachmentAPI.create(this.note);
       //this.note["_markdown"] = markdown;

        panel.classList.remove('Deleted');
    }

}
