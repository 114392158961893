import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { getPersonaLabel, getUserProfileImageElement } from 'src/app/pages/pmo/helper';
import { TenantService } from 'src/app/services/tenant.service';

@Component({
  selector: 'app-user-picture',
  templateUrl: './user-picture.component.html',
  styleUrls: ['./user-picture.component.scss'],
  standalone: true
})
export class UserPictureComponent implements OnInit {
    @Input() email: string;
    @Input() background: string;

    constructor(
        private elRef: ElementRef,
        private readonly tenant: TenantService
    ) { }

    ngOnInit() {
        if (this.background)
            this.elRef.nativeElement.style.setProperty('--background-color', this.background);

        const email = this.email.trim().toLowerCase();
        const user  = this.tenant.users.value.find(u => u.email?.trim().toLowerCase() == email);
        const imageElem = getUserProfileImageElement(user, ['small'], '48x48');
        this.elRef.nativeElement.appendChild(imageElem);
    }
}
