
<div #notePanel class="note" [attr.data-id]="note.id">
    @if (note.id == -1) {
    <div #notePanel class="notePanel" [attr.data-id]="note.id">
        <button mat-flat-button color="primary" type="button" aria-label="Undo delete" (click)="onSave()"><mat-icon>undo</mat-icon>Undo</button>
        <span class="message">This topic was deleted</span>
    </div>
    }
    @else {
    <div class="date" (click)="togglePanel()" title="Click to toggle panel">Last updated on {{note.data?.[0]?.['time'] | date}}</div>
    <div class="notePanel">
        <div class="topic" (click)="togglePanel()" [title]="note.name">{{note.name}}</div>
        <!-- The owner is the person who created the topic - the first version in the history -->
        <app-user-picture [email]="note.data?.[0]?.['owner']" [background]="bubbleColor" />
        <div class="content" (dblclick)="editNote()" [innerHTML]="contents | markdown | async"></div>
    </div>
    }
</div>

