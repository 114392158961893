import DOMPurify from 'dompurify';
import { Tenant } from "src/app/components/tenantselector/tenantselector.component";
import { DTO } from "src/dto/dto";
import { DocCreateOptions } from "src/app/pages/pmo/documentation-generator/doc-create-options/doc-create-options.interface";
import { AudienceItem } from "src/app/pages/@dialogs/event-properties/audience/audience.component";
import { User_v1 } from "src/dto/eom/user-v1";
import { UserProfile } from "src/app/types/user";


function companyNameForUser(user: AudienceItem | User_v1): string {
    let company = typeof (user as User_v1).organization == "string"
                ? JSON.parse((user as User_v1).organization as any)
                : (user as User_v1).organization
                ? (user as User_v1).organization
                : typeof (user as AudienceItem).company == "string"
                ? JSON.parse((user as AudienceItem).company)
                : (user as AudienceItem).company
                ? (user as AudienceItem).company
                : null;

    if (Array.isArray(company)) company = company[0];

    if (!company) {
        company = user.email.split('@')[1].split(".")[0];
        company = { name: company[0].toUpperCase() + company.slice(1) };
    }

    return company.name;
}


function getAssetVariables(asset: DTO) {
    return {
        title: asset.name,
        type:  DTO.getUserFriendlyName(asset.dto),
        tag:   asset.tags ? asset.tags.reduce((tags, tag) => {
            tags[tag.name.toLowerCase()] = tag.value;
            return tags;
        }, {}) : {},
        topic: asset.topics ? asset.topics.reduce((topics, topic) => {
            topics[topic.name.toLowerCase()] = topic.description;
            return topics;
        }, {}) : {}
    };
}

async function getGlobalVariables(
    asset:   DTO,
    tenant:  Tenant,
    user:    UserProfile
) {
    const criteria = ["Direct Assignment", "Creator/owner"];
    const endpoint = "api/eda/v1.0/data?name=audience&select=true";
    const refType  = DTO.getReferenceType(typeof asset.dto == "string" ? asset.dto : null) || asset.refType;
    const fullURL  = `${endpoint}&refType=${refType}&refId=${asset.id}&createdBy=${asset.createdBy}`;

    const parties: AudienceItem[] = await DTO.http.get(fullURL, undefined, true);
    const users = parties.map(user => {
        const name = user.name || user.email.split('@')[0]
            .split(".")
            .map(part => part[0].toUpperCase() + part.slice(1))
            .join(" ");

        return {
            name:    name,
            title:   user.title || user.personaName,
            email:   user.email,
            group:   user.groupNames,
            company: companyNameForUser(user)
        }
    })
    .sort((a, b) => a.group == "Creator/owner" ? -1 : 0);

    const date = new Date();
    const variables = {
        date:         date.toLocaleString(undefined, { dateStyle: "medium", timeStyle: "short" }),
        title:        asset.name,
        scope:        asset.dto + "." + asset.id,
        type:         DTO.getUserFriendlyName(asset.dto),
        description:  "Document generated by Dynatrace ACE Services via Elevate on " + date.toISOString(),
        elevate:      window.location.href.split("/#")[0],
        author: {
            name:     user.name,
            title:    user.title || user.persona,
            company:  companyNameForUser(user)
        },
        keywords:   [ "Elevate", "Dynatrace", tenant.account_name ],
        account: {
            name:     tenant.account_name,
            code:     tenant.es_tenant_name,
            logo:     `/api/efa/v2.0/file/.company/logo.png?es-tenant=${tenant.es_tenant_name}`,
        },
        contributors: users.filter(party =>  criteria.includes(party.group)),
        participants: users.filter(party => !criteria.includes(party.group)),
        footer:       "Generated by Dynatrace ACE Services via Elevate at https://elevate.dynatrace.com"
    };

    return variables;
}

function getResolvedContent(content: string, variables: any): string {
    const variableMatcher = /\{\{(?<variable>[a-zA-Z0-9_ .]+)\}\}/;

    function valueFromPropTrail(propTrail: string[], obj): string {
        const prop = propTrail.shift().toLowerCase();
        if (!obj[prop]) return "";
        if (propTrail.length == 0) return obj[prop];
        return valueFromPropTrail(propTrail, obj[prop]);
    }

    content = content.trim();

    let i = 0;
    let match: RegExpExecArray = null;
    while ((match = variableMatcher.exec(content)) && i++ < 20) {   // Max 20 substitutions.
        content = content.replace(
            match[0],               // Includes the "{{...}}".
            valueFromPropTrail(     // Find the value somewhere (deep) in the variables.
                match.groups["variable"].split('.'),
                variables
            )
            || "<i>(no value)</i>"
        );
    }

    // Sanitize any HTML in the markdown to prevent XSS attacks.
    return DOMPurify.sanitize(content);
}

function adjustLineContent(line: string, prevLine: string, hashes: string, className?: string): string {
    const headingMatcher = /^(#+) /;

    // Adjust headings to proper depth.
    if (line.startsWith("#"))
        line = '\n' + line.replace(headingMatcher, match => hashes + match) + (className ? ` {${className}}` : '') + '\n';

    // Enforce newline before start of lists.
    // Criteria: line has previous line that is not empty and is not a list item.
    if (   (line.startsWith('- ')  && prevLine?.[0] && !prevLine.startsWith('- '))
        || (line.startsWith('+ ')  && prevLine?.[0] && !prevLine.startsWith('+ '))
        || (line.startsWith('* ')  && prevLine?.[0] && !prevLine.startsWith('* '))
        || (line.startsWith("1. ") && prevLine?.[0])) // An ordered list starts with '1. '.
        line = '\n' + line;

    // Enforce newline before and after image references.
    if (line.startsWith("!["))
        line = '\n' + line + '\n';

    // Kill '---...' - they screw up the flow of the markdown.
    if (line.startsWith("---"))
        line = "";

    return line;
}

export const buildMarkdown = async (
    asset:   DTO,
    tenant:  Tenant,
    content: string = ""
): Promise<{ name: string, markdown: string, variables: any }> => {

    let markdown = "*(no value)*";

    const variables = getAssetVariables(asset);
    content = content.trim();
    if (content) {
        markdown = getResolvedContent(content, variables)
            .split(/\r?\n/)
            .map((line, i, lines) => adjustLineContent(line, lines[i - 1], "#"))
            .join('\n') + '\n\n';

        // Sanitize any HTML in the markdown to prevent XSS attacks.
        markdown = DOMPurify.sanitize(markdown);
    }

    const now = new Date();
    const name = now.toISOString();

    return { name, markdown, variables }
}

export const buildMarkdownDocument = async (
    asset:   DTO,
    tenant:  Tenant,
    user:    UserProfile,
    options: DocCreateOptions,
): Promise<{ name: string; markdown: string; variables: any }> => {

    let markdown = "";

    const buildFirstChapter = async (event: DTO, globalVariables) => {
        // NOTE: This method embodies some compromises. I wanted the first chapter to be
        // a template (albeit a partial one), but I haven't found a clean way to bring
        // unify the flow. So, for now we generate the chapter rather explicitly, with
        // Markdown and HTML. We could generate the below fully as Markdown, but because
        // of the class names we need for proper styling, it's mostly HTML. However, the
        // the content for the root event is still markdown.
        // That content goes below an H2 heading, so we add '##' to any headings present
        // in this event's content.
        // Later, children will be added as individual chapters with their own H1 headings.
        // Get available topics for this event. If they are referenced in the publishable
        // content, they will be replaced there.
        const variables = { ...globalVariables, ...getAssetVariables(asset) };

        // But also, the options can be used directly.

        const content = getResolvedContent(event.description.trim(), variables);
        const rootContent = content
            .split(/\r?\n/)
            .map((line, i, lines) => adjustLineContent(line, lines[i-1], '##', '.introduction'))
            .join('\n');
        const contributors = variables.contributors
            .map(user => `<tr><td>${user.name}</td><td>${user.company}</td><td>${user.title}</td><td>${user.email}</td></tr>`)
            .join('\n');
        const participants = variables.participants
            .map(user => user.name)
            .join(", ");

        // Conditional parts of Markdown
        let firstChapter = `# Summary and Audience {.introduction}\n\n`;
        if (options.projectDetailsVisible) {
            firstChapter += `## About this ${variables.type} {.introduction}\n\n${rootContent}\n\n`;
        }
        if (options.contributorsVisible) {
            firstChapter += `
## Contributors and Participants {.introduction}

The core contributors are listed below.

<table class="contributors"><thead>
<tr><th>Name</th><th>Organization</th><th>Title</th><th>Email</th></tr>
</thead><tbody>
${contributors}
</tbody></table>

Additional participants in this ${variables.type.toLowerCase()}
are ${participants || 'not explicitly assigned'}.`;
        }

        return (options.projectDetailsVisible || options.contributorsVisible) ? firstChapter : "";
    }

    const buildTheRest = (event: DTO, globalVariables: any, depth = 1) => {
        // Use the event's name as a heading and set it at the proper H-depth for this event.
        const hashes = "".padStart(depth, "#");
        markdown += `\n\n${hashes} ${event.name} {data-open=${event.dto}.${event.id} data-status=${event['status']}} \n`;

        const variables = { ...globalVariables, ...getAssetVariables(asset) };

        // Add the content of this event, and add the appropriate number of '#' characters to
        const content = getResolvedContent(event.description.trim(), variables);
        markdown += content
            .split(/\r?\n/)
            .map((line, i, lines) => adjustLineContent(line, lines[i - 1], hashes))
            .join('\n') + '\n\n';

        // Append the children's markdown at one level deeper.
        event['_children']?.forEach(child => buildTheRest(child, globalVariables, depth + 1));
    }

    // Get the variables for for the root event - each descendant will also use it's own.
    const globalVariables = await getGlobalVariables(asset, tenant, user);

    const variables = {
        ...globalVariables,
        tocDepth:               options.tocVisible ? 2 : 0,
        headingDepth:           options.headerNumberingVisible ? 4 : 0,
        tocVisible:             options.tocVisible,
        titlePageVisible:       options.titlePageVisible,
        projectDetailsVisible:  options.projectDetailsVisible,
        contributorsVisible:    options.contributorsVisible
    }

    // Build the first, special chapter for the root event, and then add the chapters for its children.
    markdown = await buildFirstChapter(asset, variables);
    asset['_children']?.forEach(event => buildTheRest(event, variables));

    // Sanitize any HTML in the markdown to prevent XSS attacks.
    markdown = DOMPurify.sanitize(markdown);

    // Save the complete markdown and the variables. We do this is so that the user can generate
    // a Word DOCX document (or something else!) later, and then we need both things.
    const now = new Date();
    const name = now.toISOString();

    return { name, markdown, variables };
}
