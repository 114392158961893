<div class="Header">
    <div class="title">
        <img src="/assets/img/topics.svg">{{title}}
        <elevate-tooltip [size]="16" [tipData]="tooltips['Topic']" style="margin-left: 4px; --text-subdued-color: #cccccc"></elevate-tooltip>
    </div>
    <div class="subtitle">{{subtitle}}</div>
</div>
<div class="notes">
    @for (note of notes; track note) {
        <app-topic-panel
            [attr.data-id]="note.id"
            [asset]="asset"
            [note]="note"
            [openEditor]="openEditor">
        </app-topic-panel>
    }
</div>
<div class="footer" >
    <button mat-flat-button color="primary" type="button" aria-label="Add topic"
        (click)="addNote()"><mat-icon>add</mat-icon>Add topic</button>
</div>
