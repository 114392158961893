import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ElevateTipComponent, TooltipEntries } from 'src/app/pages/general/elevate-tooltip/elevate-tooltip.component';
import { Fetch } from 'src/app/services/fetch.service';
import { UserService } from 'src/app/services/user.service';
import { MarkdownPipe } from 'src/app/utils/pipes/markdown.pipe';
import { Attachment, DTO, DTOMetaData } from 'src/dto/dto';
import { tooltips } from './tooltips';
import { UserPictureComponent } from 'src/app/components/user-picture/user-picture.component';
import { DialogService } from 'src/app/services/dialog.service';
import { HttpClientModule } from '@angular/common/http';
import { AsyncPipe, NgIf } from '@angular/common';
import { buildMarkdown } from '../markdown-editor/markdown-builder';
import { TenantService } from 'src/app/services/tenant.service';
import { FormatDate } from 'src/app/utils/pipes/datetime.pipe';
import { ThemeLoaderService } from 'src/app/services/theme-loader.service';
import { TopicPanelComponent } from "./topic-panel/topic-panel.component";

@Component({
    selector: 'app-topics',
    templateUrl: './topics.component.html',
    styleUrls: ['./topics.component.scss'],
    providers: [
        HttpClientModule
    ],
    imports: [
        MatIconModule,
        MatTooltipModule,
        MatButtonModule,
        ElevateTipComponent,
        TopicPanelComponent
    ],
    encapsulation: ViewEncapsulation.None,
    standalone: true
})
export class TopicsComponent implements AfterViewInit, OnDestroy {
    readonly tooltips: TooltipEntries = tooltips;

    notes: Attachment[] = [];

    // Initial navigation of the file manager
    // public path = "/";

    private _asset: DTO = null;
    @Input() set asset(value: DTO) {
        this._asset = value;

        this.metadata = DTO.getMetaData(value.dto);
        if (!this.metadata) {
            console.error(`${typeof value} of type ${value.dto} cannot be rendered.`);
            return;
        }
        this.ngOnInit();
    }
    get asset() {
        return this._asset || { id: -1, name: "(unknown name)", dto: "UNKNOWN"}
    }

    public title = "Topics";
    public subtitle = "";
    public metadata: DTOMetaData;
    public openEditor = () => {};

    @Input() dialog: DialogService;

    constructor(
        private readonly fetch: Fetch,
        public readonly theme: ThemeLoaderService,
        public readonly  user: UserService,
        private readonly tenant: TenantService,
        private readonly elRef: ElementRef
        //private readonly dialog: DialogService
    ) {
        this.openEditor = this.openEditDialog.bind(this);
    }

    public readonly markdownProperty = Symbol('markdownProperty');

    async ngOnInit() {
        if (!this.asset) return;

        let lastTopicName = null;
        let lastUpdatedOn = 0;
        let lastUpdatedBy = null;

        // let notes = await this.notesAPI.select(this.assetId);
        this.notes = await this.fetch.get(`/api/eda/v1.0/topic/${this.metadata._type}/${this.asset.id}`);
        this.notes.forEach(note => {
            buildMarkdown(this.asset, undefined, note.description).then(({name, markdown, variables}) => {
                note["_markdown"] = markdown;
            });
            try {
                note.data = typeof note.data == 'string'
                          ? JSON.parse(note.data)
                          : note.data || [];
            }
            catch (ex) {
                note.data = [] as any;
            }
            const lastEntry: any = note.data[note.data.length -1];
            if (lastEntry.time > lastUpdatedOn) {
                lastTopicName = note.name;
                lastUpdatedOn = lastEntry.time;
                lastUpdatedBy = lastEntry.owner.toLowerCase();
            }
        });

        if (this.notes?.length > 0) {
            this.title = this.notes.length + " Topics";
            const user  = this.tenant.users.value.find(u => u.email?.trim().toLowerCase() == lastUpdatedBy);
            this.subtitle = `Last update made by ${user?.name || lastUpdatedBy} on ${FormatDate(lastUpdatedOn)} to "${lastTopicName}".`;
        }
        else {
            this.title = "Topics";
            const typeName = DTO.getUserFriendlyName(this.asset.dto) || 'asset';
            this.subtitle = `There are no topics yet for this ${typeName.toLowerCase()}.`;
        }
    }

    ngAfterViewInit() {
    }


    public currentNoteId = null;


    addNote() {
        this.openEditDialog().then((newNote: Attachment) => {
            if (!newNote) return;
            this.notes = [...this.notes, newNote ];
            this.currentNoteId = newNote.id;
        });
        // The note panel will do the markdown prep.
    }

    ngOnDestroy() {
        // NOP
    }

    private async openEditDialog(note?: Attachment): Promise<Attachment | number> {
        return this.dialog.open('NoteEditor', {
            // This should support 'new', but also history awareness and title of the note or not.
            title:       "Topic Editor",
            icon:        "/assets/img/topics.svg",
            useAdvanced:  false,
            disableClose: false,
            isResizable:  true,
            isModal:      true,
            width:       "1200px",
            height:      "800px",
            maxHeight:   "90vh",
            maxWidth:    "90vw",
            inputs: {
                asset:    this.asset,
                note:     note,
                options: ["image"]  // NO TAGS, NO TOPICS!
            }
        });
    }
}
